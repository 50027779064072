import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";

import Logo from '../../Assets/Images/logo.svg';

const Header = props => {
  return <Fragment>
    <header>
      <Link to="/start"><div className="logo"><img src={Logo} /></div></Link>
      <div className="assessment-title" id="assessment-title">The Church Relevance test</div>
    </header>
  </Fragment>
}

export default Header;