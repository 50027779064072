import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import QuestionsTemp from '../Components/QuestionsTemp';
import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow-white.svg';
import nextArrow from '../Assets/Images/next-arrow-white.svg';

import '../App.scss';

let questionsOld = [
  {
    id: 21,
    question: "How urgently do you want to maximize your church's relevance?",
    answer: 0,
    answerTxt: "",
    type: "slider"
  },
  {
    id: 22,
    question: "What's the biggest reason why your church needs to be very relevant?",
    answer: "",
    answerTxt: "",
    type: "textarea"
  },
  {
    id: 23,
    question: "What's the biggest hurdle that keeps your church from becoming more relevant?",
    answer: "",
    answerTxt: "",
    type: "textarea"
  }
]

const AssessmentAdditional = () => {

  let navigate              = useNavigate();
  const { id }              = useParams();
  const addCtx 	            = useContext(AdditionalQuestionContext);

  
  const [searchParams, setSearchParams]   = useSearchParams();
  const [isLoading, setIsLoading]         = useState(true);
  const [questions, setQuestions]         = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep]       = useState(0);
  const [prevValue, setPrevValue]         = useState(questions[activeStep]?.answer);
  const [textInput, setTextInput]         = useState(questions[activeStep]?.answerTxt);
  // const [textField, setTextField]         = useState("");
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  // console.log("addCtx", addCtx?.questions);
  // console.log("textInput", textInput);
  // console.log("questions", questions);

  useEffect(() => {
    setTextInput(questions[activeStep]?.answerTxt)
  }, [activeStep, questions])
  
  useEffect(() => {
    document.body.style.backgroundColor = "#FBF9F2";
    getAdditional();
  }, []);

  useEffect(() => {
    if(questions[activeStep]?.answer === 0) {
      setPrevValue(0);
    } else setPrevValue(questions[activeStep]?.answer);
  }, [activeStep, questions])

  function getAdditional() {

    if(addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
    
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status === "success") {
          console.log("additional questions\n", data);
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          addCtx.addQuestions(data.questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }
  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.member == true) {
          navigate("/details/" + id + '?member=' + data.memberId, { replace: true });
        } else {
          navigate("/details/" + id, { replace: true });
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  // console.log("activeStep", activeStep);
  const prevQuestion = () => {
    if(activeStep === 0) {
      navigate("/assessment/" + id + '/review', { replace: true });
    } else { 
    setActiveStep(activeStep - 1);
    setPrevValue(questions[activeStep]?.answer);
    }
  };

  const nextQuestion = () => {
    if(activeStep < questions.length - 1) {
      setActiveStep(activeStep + 1);
      setPrevValue(questions[activeStep + 1].answer);
      addCtx.updateAnswer(questions[activeStep + 1]?.id, questions[activeStep + 1]?.answer);
      setTextInput("");
    } else {
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  };

  const nextQuestionClass = () => {
    if(activeStep === 0) {
      return "";
    } else if(activeStep === 2) {
      return "all-set-btn nextbtn";
    } else return "nextbtn";
  }

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions?.length - 1) {
      questions[activeStep].answer = answer;
      // addCtx.addQuestions(questions);
      setTimeout(() => {setActiveStep(activeStep + 1);}, 500);
    } else {      
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
      // setTimeout(() => { navigate("/details/1234", { replace: true }); }, 500);
    }
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep]?.mandatory === "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep]?.type === "slider" && questions[activeStep]?.answer !== 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.type === "textarea" && questions[activeStep]?.answerTxt !== "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  const updateQuestion = (event, step) => {
    setTextInput(event.target.value);
    questions[step].answerTxt = event.target.value;
    // return true;
  }

  const updateTextQuestion = (answer) => {
    setTextInput(answer);
    // addCtx.updateTextAnswer(questions[activeStep + 1].id, answer);
  }

  const getStepContent = (step) => {
    if(step == 0) {
      return (
        // <QuestionTemp question={questions[step]} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
        <QuestionsTemp question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} textAnswer={updateTextQuestion} currentValue={prevValue} activeStep={activeStep} />

      );
    } else {
      return <div>
        <div className="question textarea">
          <p>{questions[step]?.question}</p>
        </div>
        <div className="textarea-field">
          <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textInput}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
        </div>
      </div>
    }
    
  };

  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress  style={{'color': '#ffffff'}} size={60} /></div>
	}

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer"></div>

              <h1>MEASURING THE URGENCY FOR CHURCH RELEVANCE</h1>

              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;Prev
                </Button>
                {/* <Button onClick={nextQuestion} disabled={nextDisabled()}> */}
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  Next&nbsp;&nbsp;<img src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default AssessmentAdditional;