import React, { useState, useEffect } from 'react';

// import Slider from '@mui/material/Slider';
// import LinearProgress from '@mui/material/LinearProgress';
import { LinearProgress, Radio, Slider, TextField } from '@mui/material';


const QuestionsTemp = (props) => {

  console.log("props", props);
  // console.log("activeStep", props?.activeStep);

  const [showSlider, setShowSlider] = useState(false);
  const [progress, setProgress] = useState(props.currentValue);
  const [answerText, setAnswerText] = useState("");
  const [sliderWidth, setSliderWidth] = useState();

  useEffect(() => {
    if (props.currentValue > 0) {
      setProgress(props?.currentValue);
    }
    // console.log("props.currentValue", props?.currentValue);

    if (props?.currentValue === 0) {
      setProgress(0);
      setShowSlider(false);
    }
    else if (props?.currentValue) {
      setProgress(props?.currentValue);
      setShowSlider(true);
    }

  }, [props.currentValue])



  const getLeft = (index) => {
    if (props.question?.answers?.length === 2) {
      return index * 80;
    } else if (props?.question?.answers?.length === 3) {
      return index * 40;
    } else {
      return index * 20;
    }
  }

  const getRadioWidth = (answers) => {
    if (answers === 3) {
      return { width: "70%", marginLeft: "7.5%" };
    }
    return { width: "50%" };
  }

  const updateStep = (progress) => {

    // console.log("updateStep progress", progress);

    let slider = 0;
    if (props?.question?.answers[0].value > 0) {
      console.log("This is low");
      console.log("Progress" + progress);
      // slider    = progress * -1;
      slider = progress;
    } else {
      progress = progress;
      slider = progress;
    }
    console.log("Slider: " + slider);
    console.log("Answer: " + progress);

    setShowSlider(true);
    setTimeout(() => { setProgress(slider) }, 0);
    props?.questionAnswered(progress);
    setTimeout(() => {
      setShowSlider(false);
      setProgress(0);
    }, 500);

  }

  const updateRadioStep = (progress) => {

    setTimeout(() => { setProgress(progress) }, 0);
    props?.questionAnswered(progress);
    setTimeout(() => {
      setProgress(0);
    }, 500);

  }

  const getLabelStyle = () => {
    if (window.innerWidth > 650) {
      if (props?.question?.answers?.length === 5) {
        return { width: "100%", marginLeft: "-50%" };
      } else if (props?.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if (props?.question?.answers?.length === 3) {
        return { width: "200%", marginLeft: "-100%" };
      } else if (props?.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }
    } else {
      if (props?.question?.answers?.length === 5) {
        return { width: "120%", marginLeft: "-60%" };
      } else if (props?.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if (props?.question?.answers?.length === 3) {
        return { width: "300%", marginLeft: "-150%" };
      } else if (props?.question?.answers?.length === 2) {
        return { width: "500%", marginLeft: "-250%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }
    }
  }

  const checkOpacity = (index) => {
    if (index === 0 && showSlider) {
      return "0";
    } else {
      return "1";
    }
  }

  const checkSelected = (value) => {
    if (progress === value) {
      return "label active";
    }
    return "label";
  }


  const updateQuestion = (event, step) => {
    // console.log("updateQuestion step", step);
    // console.log(props?.question?.answers[step]);
    props.question.answerTxt = event.target.value;
    setAnswerText(event.target.value);
    props?.textAnswer(event.target.value);
    // return true;
  }

  return (
    <div>

      <div className="question">
        <p dangerouslySetInnerHTML={{ __html: props.question?.question }}></p>
      </div>

      {
        (() => {
          switch (props?.question?.type) {
            case "textarea":
              return <>
                <div className="textarea-field">
                  <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={props?.question?.answerTxt} onChange={(e) => { updateQuestion(e, props?.activeStep) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                </div>
              </>
              break;
            case "radio":
              return <>
                <div className="radio-container">
                  <div className="slider-labels" style={getRadioWidth(props?.question?.answers?.length)}>
                    {
                      props?.question?.answers?.map((data, index) => (
                        <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateRadioStep(data.value)}>
                          {/* <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <Radio checked={data.value === progress} value={data.value} />
                          <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktop_text }}></p>
                          <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobile_text }}></p>
                        </div>
                      ))
                    }
                    {/* { 
                            props?.question?.answers.map((data, index) => (
                              <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => this.updateStep(data.value)} />
                              // <a className="point" key={`points-${index}`} style={{ left: this.getLeft(index) + '%', opacity: this.checkOpacity(index) }}></a>
                            ))
                          } */}

                  </div>
                </div>
              </>
              break;
            default:
              return <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                <div className="slider">
                  {showSlider ? <Slider
                    key={props?.question?.id}
                    valueLabelDisplay="off"
                    step={1}
                    min={1}
                    max={5}
                    value={progress}
                    // value={this.updateSliderTextPosition(this.state.progress)}
                    className="custom-slider"
                  /> : <div className="slider-none-adjust"></div>}
                  <div className="slider-points">
                    {
                      props.question?.answers?.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
                      ))
                    }
                  </div>
                  <LinearProgress className="custom-bar" variant="determinate" value={0} />

                </div>
                <div className="slider-labels">
                  {
                    props?.question?.answers?.map((data, index) => (
                      <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateStep(data.value)}>
                        <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktop_text }}></p>
                        <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobile_text }}></p>
                      </div>
                    ))
                  }
                </div>
              </div>
              break;
          }
        }).call(this)
      }
    </div>
  );
}

export default QuestionsTemp;
