import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = props => {

  let section1weight        = {fontWeight: '800'};
  let section2weight        = {fontWeight: '500'};
  let section3weight        = {fontWeight: '500'};
  let section4weight        = {fontWeight: '500'};

  const [section1, setSection1] = React.useState({fontWeight: '800'});
  const [section2, setSection2] = React.useState({fontWeight: '500'});
  const [section3, setSection3] = React.useState({fontWeight: '500'});
  const [section4, setSection4] = React.useState({fontWeight: '500'});
  
  let progressWidth         = '25%';

  let progress1             = 0;
  let progress2             = 0;
  let progress3             = 0;
  let progress4             = 0;

  if(props.currentProgress < 5) {
    progress1               = ((props.currentProgress + 1) / 5) * 100;
    section1weight          = {fontWeight: '800'};
    section2weight          = {fontWeight: '500'};
    section3weight          = {fontWeight: '500'};
    section4weight          = {fontWeight: '500'};
  } else {
    progress1               = 100;
    section1weight          = {fontWeight: '500'};
  }

  if(props.currentProgress >= 5 && props.currentProgress < 10) {
    progress2               = (((props.currentProgress + 1) - 5) / 5) * 100;
    section1weight          = {fontWeight: '500'};
    section2weight          = {fontWeight: '800'};
    section3weight          = {fontWeight: '500'};
    section4weight          = {fontWeight: '500'};
  } else {
    if(props.currentProgress < 9) {
      progress2             = 0;
    } else {
      progress2             = 100;
    }
    section2weight          = {fontWeight: '500'};
  }

  if(props.currentProgress >= 10 && props.currentProgress < 15) {
    progress3               = (((props.currentProgress + 1) - 10) / 5) * 100;
    section1weight          = {fontWeight: '500'};
    section2weight          = {fontWeight: '500'};
    section3weight          = {fontWeight: '800'};
    section4weight          = {fontWeight: '500'};
  } else {
    if(props.currentProgress < 13) {
      progress3             = 0;
    } else {
      progress3             = 100;
    }
    section3weight          = {fontWeight: '500'};
  }

  if(props.currentProgress >= 15 && props.currentProgress < 20) {
    progress4               = (((props.currentProgress + 1) - 15) / 5) * 100;
    section1weight          = {fontWeight: '500'};
    section2weight          = {fontWeight: '500'};
    section3weight          = {fontWeight: '500'};
    section4weight          = {fontWeight: '800'};
  } else {
    if(props.currentProgress < 21) {
      progress4             = 0;
    } else {
      progress4             = 100;
    }
    section4weight          = {fontWeight: '500'};
  }

  return (
    <div>
            
      <div className="progress-bar">                
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress1} /></div>
          <div className="section-title" style={section1weight}>CONGREGATION</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress2} /></div>
          <div className="section-title" style={section2weight}>FEEDBACK</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress3} /></div>
          <div className="section-title" style={section3weight}>SERMON</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress4} /></div>
          <div className="section-title" style={section4weight}>COMMUNITY</div>
        </div>
      </div>

    </div>
  );
}
export default ProgressBar;