import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';

import Start from './Pages/Start';
import Assessment from './Pages/Assessment';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details';
import Results from './Pages/Results';
import Dashboard from './Pages/Dashboard';
import Invite from './Pages/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
    gtmId: 'GTM-N3LSMBS'
}

TagManager.initialize(tagManagerArgs)

function App() {
  

  const [name, setName] = useState('');

  return (
    <div className="App">
      <AdditionalQuestionContextProvider>
      <BrowserRouter>
        <Header />
        <main className="main">
          <Routes>
            <Route path="/" exact index element={<Start setName={setName} />} />
            <Route path="/start" exact element={<Start setName={setName} />}></Route>
            <Route path="/assessment" exact element={<Assessment />}></Route>
            <Route path="/assessment/:id/review" exact element={<Assessment />}></Route>
            <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route>
            <Route path="/details/:id" exact element={<Details />}></Route>
            <Route path="/results/:id" exact element={<Results />}></Route>
            <Route path="/invite-team/:id" exact element={<Invite />}></Route>
            <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
            <Route path="/dashboard/:id" exact element={<Dashboard />}></Route>
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
      </AdditionalQuestionContextProvider>
    </div>
  );
}

export default App;
