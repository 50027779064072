import React, { useEffect } from 'react';
import TickImg from '../Assets/Images/Group 8.png'
import { Link, useParams } from "react-router-dom";

const InviteThankYou = () => {

	const { id }                   	= useParams();
	
	useEffect(() => { 
		document.body.style.backgroundColor = "#F7D54D"; 
	},[]);

	return <div className="container thank-you">
		<div className="details-container">
			<div className="tick"><img src={TickImg} alt="" /></div>
			<h1>You've successfully invited your team!</h1>
			<p>You'll be notified as your team is taking the test so that you can review aggregate results and individual results.</p>
			<div className="back-btn">
				<Link to={"/results/" + id}>go back to my results</Link>
			</div>
		</div>
	</div>;
};

export default InviteThankYou;
