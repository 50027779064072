import React, { useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';

class Question extends React.Component {

  // const [showSlider, setShowSlider] = useState(false);
  // const [progress, setProgress] = useState(props.currentValue);

  constructor(props) {
    super(props);
    this.state          = {
      showSlider: false,
      progress: 0
    }
    
    // console.log(props);
    if(props.currentValue > 0) {
      this.state.progress   = props.currentValue;
    }
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.currentValue) {
      this.setState({showSlider: true});
      this.setState({ progress: nextProps.currentValue });
    }
  }
  
  checkSelected = (value) => {
    if(this.state.progress === value) {
      return "label active";
    }
    return "label";
  }

  updateStep = (progress) => {
    
    this.setState({showSlider: true});
    setTimeout(() => {this.setState({progress: progress})}, 0);
    this.props.questionAnswered(progress);
    setTimeout(() => {
      this.setState({showSlider: false});
      this.setState({progress: 0});
    }, 500);

  }

  getLeft = (index) => {
    if(this.props.question?.answers?.length === 2) {
      return index * 80;
    } else if(this.props.question?.answers?.length === 3) {
      return index * 40;
    } else {
      return index * 20;
    }
  }

  getLabelStyle = () => {
    if(window.innerWidth > 650) {

      if(this.props.question?.answers?.length === 5) {
        return { width: "100%", marginLeft: "-50%" };
      } else if(this.props.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question?.answers?.length === 3) {
        return { width: "200%", marginLeft: "-100%" };
      } else if(this.props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }

    } else {

      if(this.props.question?.answers?.length === 5) {
        return { width: "120%", marginLeft: "-60%" };
      } else if(this.props.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question?.answers?.length === 3) {
        return { width: "300%", marginLeft: "-150%" };
      } else if(this.props.question?.answers?.length === 2) {
        return { width: "500%", marginLeft: "-250%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }
    } 
  }

  render() {
    return (
      <div>
              
        <div className="question">
          <p dangerouslySetInnerHTML={{__html: this.props?.question?.question}}></p>
        </div>
  
        <div className="slider-container">
          <div className="slider">
            { this.state.showSlider ? <Slider
              defaultValue={this.state.progres}
              valueLabelDisplay="off"
              step={1}
              min={1}
              max={5}
              value={this.state.progress}
              className="custom-slider"
            /> : <div className="slider-none-adjust"></div> }
            <div className="slider-points">
            { !this.state.showSlider ? <a className="point" style={{ left: '0%'}} onClick={() => this.updateStep(1)}></a> : null }
              <a className="point" style={{ left: '20%'}} onClick={() => this.updateStep(2)}></a>
              <a className="point" style={{ left: '40%'}} onClick={() => this.updateStep(3)}></a>
              <a className="point" style={{ left: '60%'}} onClick={() => this.updateStep(4)}></a>
              <a className="point" style={{ left: '80%'}} onClick={() => this.updateStep(5)}></a>
            </div>
            <LinearProgress className="custom-bar" variant="determinate" value={0} />
            
          </div>
          <div className="slider-labels">
            { 
              this.props.question?.answers?.map((data, index) => (
                <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}>
                  <p className="desktop" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                  <p className="mobile" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                </div>
              ))
            }
            {/* <div className="label" onClick={() => this.updateStep(1)}><p>Absolutely False</p></div>
            <div className="label" style={{ left: '20%'}} onClick={() => this.updateStep(2)}><p>Likely false</p></div>
            <div className="label" style={{ left: '40%'}} onClick={() => this.updateStep(3)}><p>Unsure</p></div>
            <div className="label" style={{ left: '60%'}} onClick={() => this.updateStep(4)}><p>Likely true</p></div>
            <div className="label" style={{ left: '80%'}} onClick={() => this.updateStep(5)}><p>Absolutely true</p></div> */}
          </div>
        </div>
  
      </div>
    );
  }
  
}
export default Question;