import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import testimonialImage from '../Assets/Images/testimonial.png';
import quotationImage from '../Assets/Images/quotation.png';

import '../App.scss';

const Results = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();

  const [isLoading, setIsLoading]     = useState(true);
  const [isMember, setIsMember]       = useState(false);
  const [score, setScore]             = useState(0);
  const [subScore1, setSubScore1]     = useState(0);
  const [subScore2, setSubScore2]     = useState(0);
  const [subScore3, setSubScore3]     = useState(0);
  const [subScore4, setSubScore4]     = useState(0);

  useEffect(() => {
    document.body.style.backgroundColor = "#1F2A44";
    document.getElementById('assessment-title').style.color = "#FBF9F2";
    fetchResults();
  }, []);

  const fetchResults = () => {

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setIsLoading(false);
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
        setIsMember(data.isTeamMember);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  const showTitleText = () => {
    if(score > 70) {
      return <h1>Your church exceeds expectations</h1>
    } else if(score > 45) {
      return <h1>Your church meets expectations</h1>
    } else {
      return <h1>Your church needs improvement</h1>
    }
  }

  const showScoreText = () => {
    if(score > 70) {
      return <div className="result-text">
        Congratulations! It looks like your church is doing a great job at ensuring it stays relevant for congregants.
      </div>
    } else if(score > 45) {
      return <div className="result-text">
        While you are on a journey to ensuring your church does not become irrelevant, there's room for making it more relevant for your congregants.
      </div>
    } else {
      return <div className="result-text">
        It looks like your church needs<br/> to work towards becoming more relevant for congregants.
      </div>
    }
  }

  const showInvite = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="invite-container">

        Invite your team members to take this test and view the cumulative results so you can
        align around a strategy to combat church irrelevance.

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>Invite Your team</Button>
          </Link>
        </div>

      </div>
    }
  }

  const showTeamMember = () => {
    if(isMember) {
      return <div className="invite-container">
        Please get in touch with your team leader for the next step<br/><br/>
      </div>
    } else {
      return null;
    }
  }

  const showSchedule = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="schedule-container">
        Schedule a call with Kurian to debrief the results of your test and chart
        the best course forward to ensuring your church stays relevant.
        <div className="cta-btn">
          <a href="https://calendly.com/kurianbk/bleat-quick-call" target="_blank" rel="noreferrer">
            <Button>Talk to Kurian</Button>
          </a>
        </div>
      </div>
    }
  }

  if (isLoading) {
		return <div className="container results loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  console.log(score);

  return (
    <div>
      <div className="container results">
        
        <div className="details-container">
          
        {showTitleText()}

        <div className="progress-bar">
          <div className="si_ass_result"> 
            <div className="si_ass_progress">
              <div className="progress-line" style={{ left: score + '%'}}></div>
              <span className="msi_score" style={{ left: score + '%'}}>{score}%</span>
            </div>
            <div className="pervalue">
              <h4 className="nimp" style={{ minWidth: '45%' }}></h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div> 
            <div className="si_pro_header">
              <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br/>improvement</h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br/>expectations</h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br/>expectations</h4>
            </div> 
          </div>
        </div>

        {showScoreText()}
        

        <div className="result-sections">

          <div className="score-section">
            CONGREGATION<br/><br/>
            <div className="score-txt">{subScore1}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore1} /></div>
          </div>

          <div className="score-section">
            FEEDBACK<br/><br/>
            <div className="score-txt">{subScore2}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
          </div>

          <div className="score-section">
            SERMON<br/><br/>
            <div className="score-txt">{subScore3}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
          </div>

          <div className="score-section">
            COMMUNITY<br/><br/>
            <div className="score-txt">{subScore4}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
          </div>

        </div>
  
      </div>
        
    </div>
      
    {showInvite()}
    {showTeamMember()}

    <div className="testimonal-container">

      <div className="avatar">
        <img src={testimonialImage} />
      </div>

      <div className="testimonial">
        <img src={quotationImage} className="quotation-mark" />
        Doing this assessment gave us a better understanding of the need to constantly be relevant for our congregants,
        and helped us align around a strategy to combat church irrelevance.
        <div className="designation">
          Mike Ross | <span>Executive Pastor,<br/>North City Church</span>
        </div>
      </div>

    </div>
      
    {showSchedule()}

    </div>
  );
  
}
export default Results;