import { Fragment } from 'react';
import { Popover } from '@typeform/embed-react'


const Footer = props => {
  return <Fragment>
    <footer>
      <Popover
        chat
        id="oocpNCxy"
        // tooltip="Need help?"
      />
    </footer>
  </Fragment>
}

export default Footer;
