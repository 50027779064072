import React, { useState, useEffect } from "react";
import "../App.scss";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Tabs(props) {
    
	const [isLoading, setIsLoading]   						= React.useState(true);

	const [toggleState, setToggleState] 					= useState(1);
	const [showMoreDetails, setShowMoreDetails] 	= useState(false);
	const [questions, setQuestions] 							= useState([]);
	const [toggleMore, setToggleMore] 						= useState(0);	
	const [section1saligned, setSection1sAligned]	= useState([]);
	const [section1sout, setSection1sOut]					= useState([]);
	const [section2saligned, setSection2sAligned]	= useState([]);
	const [section2sout, setSection2sOut]					= useState([]);
	const [section3saligned, setSection3sAligned]	= useState([]);
	const [section3sout, setSection3sOut]					= useState([]);
	const [section4saligned, setSection4sAligned]	= useState([]);
	const [section4sout, setSection4sOut]					= useState([]);

	useEffect(() => {
    updateContent(); 
  }, []);

	const updateContent = () => {

		let members 					= props.members;
		let questions 				= props.questions;

		questions.forEach(question => {
			
			let answer1 				= 0;
			let answer2 				= 0;
			let answer3 				= 0;
			let answer4 				= 0;
			let answer5 				= 0;
			let aligned 				= false;
			let alignedAnswer 	= 0;
			let memAnswers 			= [];
			
			members.forEach(member => {
				let answers 			= member.answers.find(function(obj) { return obj.question_id == question.id; });
				memAnswers.push({
					name: member.fullName,
					answer: answers.answer
				});
				if(answers.answer == 1) { answer1++; }
				if(answers.answer == 2) { answer2++; }
				if(answers.answer == 3) { answer3++; }
				if(answers.answer == 4) { answer4++; }
				if(answers.answer == 5) { answer5++; }
			});

			let answerArray 		= [
				{id: 1, margin: answer1 / members.length},
				{id: 2, margin: answer2 / members.length},
				{id: 3, margin: answer3 / members.length},
				{id: 4, margin: answer4 / members.length},
				{id: 5, margin: answer5 / members.length}
			];
			answerArray.sort((a, b) => b.margin - a.margin);
			if(answerArray[0].margin > 0.5) {
				aligned 					= true;
				alignedAnswer 		= answerArray[0].id;
			}

			question.aligned 		= aligned;
			question.alignedAns	= alignedAnswer;
			question.members 		= memAnswers;
			question.open 			= false;

		});

		let section1Aligned		= questions.filter(function (obj) { return obj.sectionId == 1 && obj.aligned; });
		setSection1sAligned(section1Aligned);
		let section1Out				= questions.filter(function (obj) { return obj.sectionId == 1 && !obj.aligned; });
		setSection1sOut(section1Out);
		let section2Aligned		= questions.filter(function (obj) { return obj.sectionId == 2 && obj.aligned; });
		setSection2sAligned(section2Aligned);
		let section2Out				= questions.filter(function (obj) { return obj.sectionId == 2 && !obj.aligned; });
		setSection2sOut(section2Out);
		let section3Aligned		= questions.filter(function (obj) { return obj.sectionId == 3 && obj.aligned; });
		setSection3sAligned(section3Aligned);
		let section3Out				= questions.filter(function (obj) { return obj.sectionId == 3 && !obj.aligned; });
		setSection3sOut(section3Out);
		let section4Aligned		= questions.filter(function (obj) { return obj.sectionId == 4 && obj.aligned; });
		setSection4sAligned(section4Aligned);
		let section4Out				= questions.filter(function (obj) { return obj.sectionId == 4 && !obj.aligned; });
		setSection4sOut(section4Out);
		// setSection1(section1);

		setQuestions(questions);
		setIsLoading(false);
		
	}

	const toggleTab = (index) => {
		setToggleState(index);
	};

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <RemoveIcon/>
		} else {
			return <AddIcon/>
		}
	}

	const showAnswerText = (answer) => {
		console.log(answer);
		if(answer == 1) {
			return <div className="answer false">ABSOLUTELY FALSE</div>
		} else if(answer == 2) {
			return <div className="answer false">LIKELY FALSE</div>
		} else if(answer == 3) {
			return <div className="answer neutral">UNSURE</div>
		} else if(answer == 4) {
			return <div className="answer true">LIKELY TRUE</div>
		} else if(answer == 5) {
			return <div className="answer true">ABSOLUTELY TRUE</div>
		}
	}

	const showIndividualAnswerText = (answer) => {
		console.log(answer);
		if(answer == 1) {
			return <div className="more-answer false">ABSOLUTELY FALSE</div>
		} else if(answer == 2) {
			return <div className="more-answer false">LIKELY FALSE</div>
		} else if(answer == 3) {
			return <div className="more-answer neutral">UNSURE</div>
		} else if(answer == 4) {
			return <div className="more-answer true">LIKELY TRUE</div>
		} else if(answer == 5) {
			return <div className="more-answer true">ABSOLUTELY TRUE</div>
		}
	}

	if (isLoading) {
		return null;
	}

	return (
		<div className="container-tabs">
			<div className="tab-buttons">
        <button className={toggleState === 1 ? "active" : ""} onClick={() => toggleTab(1)}>Congregation</button>
        <button className={toggleState === 2 ? "active" : ""} onClick={() => toggleTab(2)}>Feedback</button>
        <button className={toggleState === 3 ? "active" : ""} onClick={() => toggleTab(3)}>Sermon</button>
        <button className={toggleState === 4 ? "active" : ""} onClick={() => toggleTab(4)}>Community</button>
      </div>

			<div className="tabs-content">
				<div className={toggleState === 1 ? "content  active-content" : "content"}>

					{
						section1saligned.length > 0 ?
							<div className="align-details">
								<p className="green">Celebrate current areas of great alignment</p>
								{
									section1saligned.map((data, index) => (
										<div className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section1sout.length > 0 ?
						<div className="align-details">
							<p className="red">Analyze potential areas for better alignment</p>
							{
								section1sout.map((data, index) => (
									<div className="answered-detail">
										<a className="more-toggle" onClick={() => toggleDetails(data.id)}>
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((data, index) => (
													<div className="more-row"><p>{data.name}</p> {showIndividualAnswerText(data.answer)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}

				</div>

				<div className={toggleState === 2 ? "content  active-content" : "content"}>
					{
						section2saligned.length > 0 ?
							<div className="align-details">
								<p className="green">Celebrate current areas of great alignment</p>
								{
									section2saligned.map((data, index) => (
										<div className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section2sout.length > 0 ?
						<div className="align-details">
							<p className="red">Analyze potential areas for better alignment</p>
							{
								section2sout.map((data, index) => (
									<div className="answered-detail">
										<a className="more-toggle" onClick={() => toggleDetails(data.id)}>
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((data, index) => (
													<div className="more-row"><p>{data.name}</p> {showIndividualAnswerText(data.answer)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>

				<div className={toggleState === 3 ? "content  active-content" : "content"}>
					{
						section3saligned.length > 0 ?
							<div className="align-details">
								<p className="green">Celebrate current areas of great alignment</p>
								{
									section3saligned.map((data, index) => (
										<div className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section3sout.length > 0 ?
						<div className="align-details">
							<p className="red">Analyze potential areas for better alignment</p>
							{
								section3sout.map((data, index) => (
									<div className="answered-detail">
										<a className="more-toggle" onClick={() => toggleDetails(data.id)}>
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((data, index) => (
													<div className="more-row"><p>{data.name}</p> {showIndividualAnswerText(data.answer)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>
                
				<div className={toggleState === 4? "content  active-content" : "content"}>
					{
						section4saligned.length > 0 ?
							<div className="align-details">
								<p className="green">Celebrate current areas of great alignment</p>
								{
									section4saligned.map((data, index) => (
										<div className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns)}
										</div>
									))
								}
							</div>
						: null
					}
                        
					{
						section4sout.length > 0 ?
						<div className="align-details">
							<p className="red">Analyze potential areas for better alignment</p>
							{
								section4sout.map((data, index) => (
									<div className="answered-detail">
										<a className="more-toggle" onClick={() => toggleDetails(data.id)}>
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((data, index) => (
													<div className="more-row"><p>{data.name}</p> {showIndividualAnswerText(data.answer)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
						</div>
						: null
					}
				</div>
			</div>
		</div>
	);
}

export default Tabs;