import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

let questionsOld = [
  {
    id: 1,
    sectionId: 1,
    question: "Even if the Superbowl happened on a Sunday morning instead of a Sunday evening, your church attendance would have remained the same.",
    answer: 0,
  },
  {
    id: 2,
    sectionId: 1,
    question: "Your congregation is coming to church because it is truly relevant in their lives and not just out of obligation.",
    answer: 0,
  },
  {
    id: 3,
    sectionId: 1,
    question: "Struggling congregants would talk to another person in church about their problems rather than watch Netflix or drink wine to forget it.",
    answer: 0,
  },
  {
    id: 4,
    sectionId: 1,
    question: "Your congregants are frequently sharing their lives (more than their Wordle scores) with one another.",
    answer: 0,
  },
  {
    id: 5,
    sectionId: 1,
    question: "If your congregants think of the 5 most relevant brands in their life, your church would be on that list.",
    answer: 0,
  },
  {
    id: 6,
    sectionId: 2,
    question: "Your board, leadership, and staff have invested in tools to ask and listen to your congregants for feedback.",
    answer: 0,
  },
  {
    id: 7,
    sectionId: 2,
    question: "Your board, leadership, and staff frequently listen to the voice of your congregants.",
    answer: 0,
  },
  {
    id: 8,
    sectionId: 2,
    question: "Your congregants feel that your board, leadership, and staff always hear their feedback and act upon it.",
    answer: 0,
  },
  {
    id: 9,
    sectionId: 2,
    question: "You know what it means to be relevant to your congregation and local community because you have asked and learned from them.",
    answer: 0,
  },
  {
    id: 10,
    sectionId: 2,
    question: "You make data-driven decisions instead of just gut calls.",
    answer: 0,
  },
  {
    id: 11,
    sectionId: 3,
    question: "Your congregants get their doubts about your weekend sermon answered instead of letting them go unclarified.",
    answer: 0,
  },
  {
    id: 12,
    sectionId: 3,
    question: "Your congregants don’t forget your sermon by Wednesday of the following week.",
    answer: 0,
  },
  {
    id: 13,
    sectionId: 3,
    question: "You give your congregants practical next steps to take based on the sermons they hear.",
    answer: 0,
  },
  {
    id: 14,
    sectionId: 3,
    question: "You are aware of how your congregants are applying your semon’s learnings in their lives during the week.",
    answer: 0,
  },
  {
    id: 15,
    sectionId: 3,
    question: "You offer congregants tools to measure their growth and maturity in Christ apart from just teaching them through the weekend sermon.",
    answer: 0,
  },
  {
    id: 16,
    sectionId: 4,
    question: "Your congregation feels assured in inviting others to your church because they know that it will be a relevant introduction that they won’t have to be uncomfortable about.",
    answer: 0,
  },
  {
    id: 17,
    sectionId: 4,
    question: "Your message to the local community around you isn’t ‘come to church’. Instead, you go to meet them where they are.",
    answer: 0,
  },
  {
    id: 18,
    sectionId: 4,
    question: "While services run on the weekends, your church (and building) is resourcing and serving your local community during the week as well.",
    answer: 0,
  },
  {
    id: 19,
    sectionId: 4,
    question: "During an earthquake or flooding, your local community knows that your church will open its doors and offer food and shelter for all.",
    answer: 0,
  },
  {
    id: 20,
    sectionId: 4,
    question: "Your church and congregants are talked about positively by members in your local community and city.",
    answer: 0,
  }
]

const Assessment = () => {
  let navigate        = useNavigate();
  const { id }        = useParams();
  

  useEffect(() => {
    document.body.style.backgroundColor = "#FBF9F2";
  }, []);

  const [searchParams, setSearchParams]          = useSearchParams();
  const [isLoading, setIsLoading]                = useState(false);
  const [activeStep, setActiveStep]              = useState(0);
  const [sections, setSections]                  = useState([]);
  const [questionsFromApi, setQuestionsFromApi]  = useState([]);
  const [questions, setQuestions]                = useState([]);
  const [prevValue, setPrevValue]                = useState(questions[questions.length - 1]?.answer);
  const [questionCount, setQuestionCount]        = useState(0);
  const [currentHash, setCurrentHash]            = useState(0);
  
  const [apiData, setApiData] = useState(null);
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);
  
  // console.log("apiData", apiData);

  function getAssessment() {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status === "success") {
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1]?.answer);
        setCurrentHash(id);
        // setApiData(data);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  useEffect(() => {
    id ? getAssessment() : getQuestions();
  }, [id])


  function getQuestions() {

    setIsLoading(true);
    let clientId              = process.env.REACT_APP_CLIENT_ID;
    
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
      console.log(data);
			if(data.status === "success") {
        console.log(data);
				setSections(data.sections);
				setQuestions(data.questions);
        // setQuestionsFromApi(data.questions);
        setQuestionCount(data.questions.length);
        setIsLoading(false);
			} else {
				// setOpenSnackbar(true);
			}
		});

  }

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

		let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				clientId,
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        setIsLoading(false);
				navigate("/assessment-additional/" + data.id, { replace: true });
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const prevQuestion = () => {
    if(activeStep < 1) {
      navigate("/start", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1]?.answer);
    }
  };

  // const nextQuestion = () => {
  //   if(activeStep < questions.length - 1) {
  //     setActiveStep(activeStep + 1);
  //   }
  //   setIsLoading(true);
  // };
  const nextQuestion = () => {
    if(activeStep < questions?.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1]?.answer);
      }, 20);
    } else if(currentHash != 0) {
      navigate("/assessment-additional/" + currentHash, { replace: true });
    } else setIsLoading(true);
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions?.length - 1) {
      questions[activeStep].answer = answer;
      setTimeout(() => {setActiveStep(activeStep + 1);}, 500);
    } else {      
      questions[activeStep].answer = answer;
      setTimeout(() => { completeAssessment(); }, 500);
      // setTimeout(() => { navigate("/details/1234", { replace: true }); }, 500);
    }
    
  }

  // const nextDisabled = (answer) => {
  //   console.log("activeStep", activeStep);
  //   return true;
  // }
  const nextDisabled = (answer) => {
    // console.log("questions[activeStep].answer", questions[activeStep]?.answer);
    // console.log("questions[activeStep]", questions[activeStep]);
    if(questions[activeStep]?.mandatory == "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep]?.type == "slider" && questions[activeStep]?.answer !== 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.type == "radio" && questions[activeStep]?.answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.type == "textarea" && questions[activeStep]?.answerTxt != "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  // console.log("currentValue prevValue", prevValue);
  // let answerValues = questions?.map(q => q.answers?.map(a => a.value));
  // console.log(answerValues);
  let selectedAnswers = questions?.map(q => q.answer);
  console.log(selectedAnswers);

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	} 
  
  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              <div className="spacer"></div>

              <ProgressBar currentProgress={activeStep}/>
              
              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions?.length}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;Prev
                </Button>
                {/* <Button onClick={nextQuestion} disabled={nextDisabled()}> */}
                <Button onClick={nextQuestion} style={nextDisabled()}>
                  Next&nbsp;&nbsp;<img src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;